.ui.button.primary,
.ui.button.primary:hover {
  background-color: var(--primary) !important;
}

.ui.button.secondary,
.ui.button.secondary:hover {
  background-color: var(--secondary) !important;
}

.main-menu {
  background-color: var(--primary);
}
