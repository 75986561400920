.main-menu {
  background-color:  var(--primary) !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.navslide {
  margin-left: 190px;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}

.main-menu .item {
  color: #ffffff !important;
}

.main-menu .item.active {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.fixed.main-menu,
.ui.top.fixed.menu {
  height: 66px;
}

.ui.top.fixed.menu {
  border: 0px !important;
}

.ui.menu .item {
  padding: 20px !important;
}

.logo-text {
  color: #3b3d3e;
  font-size: 18px;
  margin-left: 10px;
  text-align: center;
  font-weight: bold;
}

.ui.segment {
  border-radius: 0px !important;
  border: 0px !important;
}
