.main-container{
  min-height: 100vh;
  background-color: white;
}

.wrapper {
  min-height: auto;
  margin-top: 64px;
  padding: 16px;
}

.header-page {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
}

.fragment-container {
  padding: 10px;
  margin-top: 14px;
}

.wrapper > .content {
  overflow: auto;
  position: absolute;
  top: 0;
  left: 276px;
  height: 100%;
  width: calc(100% - 276px);
}

.full-height {
  margin-top: 0px !important;
  min-height: 100%;
  height: 100vh;
}

.ui.container {
  width: 100%;
  padding: 0px 50px;
  margin: 0 !important;
  /* background-color: #ECECEC; */
  height: calc(100vh - 70px);
  border: 1px solid #ECECEC;
  overflow: auto;
}

.full-width {
  width: 100% !important;
}
.input-error {
  border: 1px solid red !important;
}
div.rte {
  min-height: 250px;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: normal;
}
